<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>报修信息</h1>
      </el-header>
      <el-main class="scrollable-main">
        <el-form label-width="100px" size="large" style="width: 90%">
          <el-form-item label="报修人">
            <span>{{ users.find(item => item.id === reports.userId) ? users.find(item => item.id === reports.userId).nickname : ''}}</span>
          </el-form-item>
          <el-form-item label="车间">
            <span>{{getLineName(reports.lineId)}}</span>
          </el-form-item>
          <el-form-item label="设备">
            <span>{{ reports.equipmentName }}</span>
          </el-form-item>
          <el-form-item label="工单类型">
            <span>{{ reports.type }}</span>
          </el-form-item>
          <el-form-item label="故障类型">
            <span>{{ reports.failureType }}</span>
          </el-form-item>
          <el-form-item label="报修时间">
            <span>{{ reports.recordTime }}</span>
          </el-form-item>
          <el-form-item label="故障时间">
            <span>{{ reports.failureTime }}</span>
          </el-form-item>
          <el-form-item label="故障描述">
            <span>{{ reports.description }}</span>
          </el-form-item>
          <el-form-item label="故障图">
            <div style="display: flex; flex-wrap: wrap; gap: 10px;">
              <el-image
                  v-for="(file, index) in reportFiles"
                  :key="index"
                  :src="file.fileUrl"
                  :preview-src-list="reportFiles.map(item => item.fileUrl)"
                  style="width: 100px; height: 100px; margin-bottom: 10px;"
              >
              </el-image>
            </div>
          </el-form-item>
        </el-form>
        <el-row type="flex" justify="center">
          <el-button @click="goToHome" size="large">返回</el-button>
          <el-button v-if="reports.state === '等待中'" type="primary" @click="startRepair" size="large" style="margin-left: 20px">接单</el-button>
        </el-row>
      </el-main>
      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "MReportDetail",
  data() {
    return {
      form: {},
      reports: [],
      users: [],
      lines: [],
      reportFiles: [],
      reportId: null,
      userRole: "",
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      console.log('item id:', this.$route.params.id);
      this.reportId = this.$route.params.id;
      this.request.get("/repairReport/"+this.reportId).then(res => {
        this.reports = res.data;
        console.log('userId:', this.reports.userId);
        this.request.get("/user").then(res => {
          this.users = res.data;
          this.userRole = this.users.find(item => item.id === this.user.id).role;
          console.log('userRole:', this.userRole)
        });
      });
      this.request.get("/line").then(res => {
        this.lines = res.data
      })
      this.request.get("/reportFile/getByReportId/"+this.reportId).then(res => {
        this.reportFiles = res.data
      })
    },
    // 返回首页
    goToHome() {
      this.$router.push('/mobile');
    },
    getLineName(lineId) {
      const line = this.lines.find(v => v.id === lineId);
      if (!line) return '';
      return line ? line.name : '';
    },
    startRepair() {
      this.request.get("/failureRecord/findByReportId/"+this.reportId).then(res => {
        if (res.data) {
          this.$message.error("该工单已经被接单");
        } else {
            const formData = {
              reportId: this.reportId,
              userId: this.user.id, // 接单人的 id
              equipmentId: this.reports.equipmentId,
              equipmentName: this.reports.equipmentName,
              type: this.reports.type,
              failureType: this.reports.failureType,
              partId: this.reports.partId,
              lineId: this.reports.lineId,
              description: this.reports.description
            };
            this.request.post("/failureRecord", formData).then(res => {
              if (res.code === '200') {
                this.$message.success("接单成功")
                this.dialogFormVisible = false
                this.$router.push('/mobile');
              } else {
                this.$message.error("接单失败")
              }
            })
            this.request.post("/repairReport/start/" + this.reportId)
            //this.request.post("/user/start/" + this.user.id)
            }
        })
      }
  }
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>
